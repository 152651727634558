import React from 'react'
import './Footer.css';
import logo from '../../Assets/chinatown-logo.png'
//import facebook from '../../Assets/facebook.png'
import facebook from '../../Assets/facebook-copy.png'
//import viber from '../../Assets/viber.png'
import viber from '../../Assets/viber-copy.png'
//import messenger from '../../Assets/messenger.png'
import messenger from '../../Assets/messenger-copy.png'


function Footer() {
  return (
    <footer>
        <div className='footerContainer'>
            <div className='footerHeader'>
                <div className='footerLogoContainer'> 
                    <a href='/'>
                        <img className='footerCEGLogo' src={logo}/>
                    </a> 
                </div>
                <div className='footerTitleContainer'>
                    <a target="_blank" href='https://mytsoftdevsolutions.com/'>
                        <p className='footerTitle'>Powered by MYT Softdev Solutions, Inc.</p>
                    </a>
                </div>
                <div className='socialLinks'>
                    <div className='socmedLogoContainer'>
                        <a target="_blank" href='https://www.facebook.com/ChinatownEastGate'>
                        <img className='footerLogo' src={facebook}/>
                        </a>
                    </div>
                    <div className='socmedLogoContainer'>
                        <a target="_blank" href='tel: +63 917 186 8880'>
                        <img className='footerLogo' src={viber}/>
                        </a>
                    </div>
                    <div className='socmedLogoContainer'>
                        <a target="_blank" href='https://www.messenger.com/t/106034028441299'>
                        <img className='footerLogo' src={messenger}/>
                        </a>
                    </div>
                </div>
            </div>  
        </div>
    </footer>
  );
}

export default Footer;