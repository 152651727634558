//import React from 'react'
import React, { useState } from 'react'
import './Store.css'
import Footer from '../../components/Footer/Footer';
import NavBar from '../../components/NavBar/NavBar';
import logo from '../../Assets/chinatown-logo.png'
import photo from '../../Assets/chinatown-photo.png'
//import time from '../../Assets/time.png'
import time from '../../Assets/time-copy.png'
//import contact from '../../Assets/contact.png'
import contact from '../../Assets/contact-copy.png'
//import mail from '../../Assets/mail.png'
import mail from '../../Assets/mail-copy.png'
//import checkIcon from '../../Assets/check-icon.png';
import checkIcon from '../../Assets/check.png';
import sevenElevenImage from '../../Assets/SevenElevenImage.png';
import Carousel from 'react-bootstrap/Carousel';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import { useNavigate,useLocation} from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';


function Store({ userId }) {
    const navigate = useNavigate()
//     const user = data.find((user) => user.id === userId);

//   if (!user) {
//     return <div>User not found</div>;
//   }
    const location = useLocation();
    console.log(location.state);
    const data = location.state.items;
    console.log("data", data);

    //to display specific data from object 

    const amenityProperties = ['amneties1', 'amneties2', 'amneties3', 'amneties4', 'amneties5', 'amneties6'];
    console.log(amenityProperties)

    //
    //
    const serviceProperties = ['service1', 'service2', 'service3', 'service4', 'service5', 'service6', 'service7', 'service8'];
    console.log(serviceProperties)
    //

    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const handleScroll = (event, id) => {
        event.preventDefault();

        const element = document.getElementById(id);
        if (element) {
            window.scrollTo({
                top: element.offsetTop,
                behavior: 'smooth',
            });
        }
    };
        //
    //
    
    return (
        <div className="App">
            <NavBar/>
             <div>
                
                    {/* <header className="App-header">
                        <img src={logo} className="logo" alt="logo" />
                    </header> */}
                    {/* <div className='chinatownPhoto'>
                        <img src={photo} className="photo" alt="photo" />
                    </div> */}
                    {/* <div>
                        <button type="button" className="backBtn" onClick={()=>navigate("/directory")}>BACK</button>
                    </div> */}
                    
                    <Row>
                        
                        <Col sm="3">
                                <div className='chinatown-photos'>
                                    <img src={data.image} className='chnImg' style={{width:'255px'}}></img>
                                </div>
                            <Row>
                                <Col>
                                    <h2 className='storeName'>{data.title}</h2>
                                </Col>
                            </Row>
                            <Row className='hrsContact' style={{ marginTop: '10px'}}>
                                {/*<Col sm="4">*/}
                                <Col sm="4" style={{width: '100%'}}>
                                    {data.text ? ( 
                                        <div className='timeStore-tel'>
                                        <img src={time} className='timeStore store' alt='Time' style={{ width: '20px', height:'20px'}}></img>
                                        <span className='info-time'>{data.text}</span>
                                        </div>
                                    ) : null }
                                </Col>
                                {/*<Col sm="4">*/}
                                <Col sm="4" style={{width: '100%'}}>
                                    {data.text1 ? (
                                        <a href={`tel:${data.text1}`} target="_blank" className="contactStore-tel-href">                                  
                                            <img src={contact} className='contactStore-tel store' alt='Contact' style={{ width: '20px', height:'20px'}}></img>
                                            <span className='info-contact'>{data.text1}</span>
                                        </a>
                                    ) : null }
                                {data.telnum ? (
                                    <a href={`tel:${data.telnum}`} target="_blank" className="contactStore-tel-href">
                                        <img src={contact} className='contactStore-tel store' alt='Contact' style={{ width: '20px', height: '20px' }}></img>
                                        <span className='info-contact'>{data.telnum}</span>
                                    </a>
                                ) : null}
                                </Col>
                                {/*<Col sm="4">*/}
                                <Col sm="4" style={{width: '100%', wordWrap: 'break-word'}}>
                                    {data.text2 ? (
                                        /* <a href="https://mail.google.com/mail/u/0/#inbox" target="_blank" className="contactStore-tel-href">*/                                        
                                        <a href={`mailto:${data.text2}`} target="_blank" className="mailStore-tel-href">
                                            <img src={mail} className='mail-box store' alt='Email' style={{ width: '20px', height:'20px'}}  ></img>
                                            <span className='info-email'>{data.text2}</span>
                                        </a>
                                    ) : null}
                                </Col>
                            </Row>
                            <div>
                            {/* Close button (X) */}
                            <a
                                href="/#homeTo"
                                className="close-button"
                                onClick={(event) => {
                                    closeMobileMenu();
                                    //handleScroll(event, "homeTo");
                                }}
                                
                            >
                                &times; {/* This represents the "X" character */}
                            </a>
                        </div>
                        </Col> 

                        <Col sm="9" className="amenServ">
                        

                    {amenityProperties.some(property => data[property] && data[property].trim() !== '') && ( 
                        <div className="section-container">
                            {/*<Row style={{ marginTop: '30px' }}>*/}
                            
                            <Row className="titleRow">
                                <Col>
                                    <h3 className='amnetiesStore' style={{ fontSize: '20px' }}>Amenities</h3>
                                </Col>                    
                            </Row>
                           

                            {/* <Row>
                                <Col sm="3" className='col-margin'>
                                    <img src={checkIcon} className='small-icon' alt='Check'></img>
                                    <span className='amneties-name'>{data.amneties1}</span>
                                </Col>
                                <Col sm="3" className='col-margin'>
                                    <img src={checkIcon} className='small-icon' alt='Check'></img>
                                    <span className='amneties-name'>{data.amneties2}</span>
                                </Col>
                                <Col sm="3" className='col-margin'>
                                    <img src={checkIcon} className='small-icon' alt='Check'></img>
                                    <span className='amneties-name'>{data.amneties3}</span>
                                </Col>
                                <Col sm="3" className='col-margin'>
                                    <img src={checkIcon} className='small-icon' alt='Check'></img>
                                    <span className='amneties-name'>{data.amneties4}</span>
                                </Col>
                            </Row> */}

                            <Row className="bodyRow">
                                {amenityProperties
                                    .filter(property => data[property] && data[property].trim() !== '') // Filter out empty properties
                                    .map((property, index) => (
                                    <Col sm="12" className="col-margin" key={index}>
                                        <img src={checkIcon} className="small-icon" alt="Check" />
                                        <span className="amenities-name">{data[property]}</span>
                                    </Col>
                                    ))}
                            </Row>
                        </div>
                    )}

                    {serviceProperties.some(property => data[property] && data[property].trim() !== '') && (
                        <div className="section-container">
                        {/*<Row style={{ marginTop: '30px' }}>*/}
                        <Row className="titleRow">
                            <Col>
                                <h3 className='servicesStore' style={{ fontSize: '20px' }}>Services</h3>
                            </Col>                    
                        </Row>

                            <Row className="bodyRow">
                                {serviceProperties
                                    .filter(property => data[property] && data[property].trim() !== '') // Filter out empty properties
                                    .map((property, index) => (
                                    <Col sm="12" className="col-margin" key={index}>
                                        <img src={checkIcon} className="small-icon" alt="Check" />
                                        <span className="services-name">{data[property]}</span>
                                    </Col>
                                    ))}
                            </Row>
                        </div>
                    )}
                        </Col>
                        {/* <div className='store-col' style={{lineHeight:'90px'}}>
                           <div><img src={time} className='timeStore'></img><span className='info-time'>{data.text}</span>
                           </div> 
                           <div><p className='info-time'>{data.text}</p></div> 
                        </div> */}
                        {/* <div className='info-col'>
                            <p className='info-time'>{data.text}</p>
                        </div>
                        <div className='store-col' style={{lineHeight:'90px'}}>
                            <div><img src={contact} className='contactStore'></img><span className='info-contact'>{data.text1}</span></div>
                            <div><p className='info-contact'>{data.text1}</p></div>
                        </div>
                        <div className='info-col'>
                            <p className='info-contact'>{data.text1}</p>
                        </div> */}
                        {/* {
                            data.text2 ? (
                                <>
                                <div className='store-col' style={{lineHeight:'90px'}}>
                                    <div><img src={mail} className='mail'></img><span className='info-email'>{data.text2}</span></div>
                                    <div><p className='info-email'>{data.text2}</p></div>
                                </div>
                                <div className='info-col'>
                                    <p className='info-email'>{data.text2}</p>
                                </div>
                                </>
                            ) : (<p></p>)
                        } */}
                        {/* <div className='store-col'>
                            <img src={mail} className='mail'></img>
                        </div>
                        <div className='store-col'>
                            <p>{data.text2}</p>
                        </div> */}
                    </Row>
                        {/* <div>
                            <div className='store-col'>
                                <img src={checkIcon} className='checkStore'></img>
                            </div>
                            <div className='store-col'>
                                <p>Work and Lounging Area</p>
                            </div>   
                        </div> */}
                    {/* <div className='info-wrapper'>
                        <div className='timeStore-wrapper'>
                             <img src={time}></img>
                            <p>{data.text}</p>
                        </div>
                       
                    </div> */}
                    
                    {/* <div className='descStore'>
                        <p>
                            {data.description}
                        </p>
                    </div> */}
                    <br/>
                    <Row className='galleryRow'>
                        <h4 className='galleryStore'>Gallery</h4>
                    </Row>

                    <Row>
                        
                        {
                            data.id1 ? (
                            //data.id1 && (
                                <>
                                <div className="d-flex justify-content-center">
                                <Carousel className="carousel-container" controls={data.image2 ? true : false} indicators={data.image2 ? true : false}>
                                {data.image1 && ( 
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image1}/>
                                    </Carousel.Item>
                                )}
                                {data.image2 && (
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image2}/>
                                    </Carousel.Item>
                                )}
                                {data.image3 && (
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image3}/>
                                    </Carousel.Item>
                                )}
                                {data.image4 && (
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image4}/>
                                    </Carousel.Item>
                                )}
                                {data.image5 && (
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image5}/>
                                    </Carousel.Item>
                                )}
                                    
                                </Carousel>
                                </div></>
                            ) : (false)
                        }
                        {
                            data.id2 ? (
                                <>
                                <div className="d-flex justify-content-center">
                                <Carousel className="carousel-container">
                                    
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image1}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image2}/>
                                    </Carousel.Item>
                                    
                                </Carousel>
                                </div>
                                </>
                            ) : (false)
                        }
                        {
                            data.id3 ? (
                                <>
                                <div className="d-flex justify-content-center">
                                <Carousel className="carousel-container" controls={data.image2 ? true : false} indicators={data.image2 ? true : false}>
                                    
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image1}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image2}/>
                                    </Carousel.Item>
                                    
                                </Carousel>
                                </div>
                                </>
                            ) : (false)
                        }
                        {
                            data.id4 ? (
                                <>
                                <div className="d-flex justify-content-center">
                                <Carousel className="carousel-container" controls={data.image2 ? true : false} indicators={data.image2 ? true : false}>
                                    
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image1}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image2}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image3}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image4}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image5}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image6}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image7}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image8}/>
                                    </Carousel.Item>
                                    
                                </Carousel>
                                </div>
                                </>
                            ) : (false)
                        }
                        {
                            data.id5 ? (
                                <>
                                <div className="d-flex justify-content-center">
                                <Carousel className="carousel-container" controls={data.image2 ? true : false} indicators={data.image2 ? true : false}>
                                    
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image1}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image2}/>
                                    </Carousel.Item>
                                    
                                </Carousel>
                                </div>
                                </>
                            ) : (false)
                        }
                        {
                            data.id6 ? (
                                <>
                                <div className="d-flex justify-content-center">
                                <Carousel className="carousel-container" controls={data.image2 ? true : false} indicators={data.image2 ? true : false}>
                                    
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image1}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image2}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image3}/>
                                    </Carousel.Item>
                                    
                                </Carousel>
                                </div>
                                </>
                            ) : (false)
                        }
                        {
                            data.id7 ? (
                                <>
                                <div className="d-flex justify-content-center">
                                <Carousel className="carousel-container">
                                    
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image1}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image2}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image3}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image4}/>
                                    </Carousel.Item>
                                    
                                </Carousel>
                                </div>
                                </>
                            ) : (false)
                        }
                        {
                            data.id8 ? (
                                <>
                                <div className="d-flex justify-content-center">
                                <Carousel className="carousel-container">
                                    
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image1}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image2}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image3}/>
                                    </Carousel.Item>
                                    
                                </Carousel>
                                </div>
                                </>
                            ) : (false)
                        }
                        {
                            //data.id9 ? (
                            data.id9 && (
                                <>
                                <div className="d-flex justify-content-center">
                                <Carousel className="carousel-container" controls={data.image2 ? true : false} indicators={data.image2 ? true : false}>
                                {data.image1 && (
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image1} alt="Slide 1"/>
                                    </Carousel.Item>
                                )}
                                </Carousel>
                                </div>
                                </>
                            ) //: (false)
                        }
                        {
                            data.id10 ? (
                                <>
                                <div className="d-flex justify-content-center">
                                <Carousel className="carousel-container">
                                    
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image1}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image2}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image3}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image4}/>
                                    </Carousel.Item>
                                    
                                </Carousel>
                                </div>
                                </>
                            ) : (false)
                        }
                        {
                            data.id11 ? (
                                <>
                                <div className="d-flex justify-content-center">
                                <Carousel className="carousel-container">
                                    
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image1}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image2}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image3}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image4}/>
                                    </Carousel.Item>
                                    
                                </Carousel>
                                </div>
                                </>
                            ) : (false)
                        }
                        {
                            data.id12 ? (
                                <>
                                <div className="d-flex justify-content-center">
                                <Carousel className="carousel-container">
                                    
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image1}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image2}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image3}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image4}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image5}/>
                                    </Carousel.Item>
                                    
                                </Carousel>
                                </div>
                                </>
                            ) : (false)
                        }
                        {
                            data.id13 ? (
                                <>
                                <div className="d-flex justify-content-center">
                                <Carousel className="carousel-container">
                                    
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image1}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image2}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image3}/>
                                    </Carousel.Item>
                                    
                                </Carousel>
                                </div>
                                </>
                            ) : (false)
                        }
                        {
                            data.id14 ? (
                                <>
                                <div className="d-flex justify-content-center">
                                <Carousel className="carousel-container">
                                    
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image1}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image2}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image3}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image4}/>
                                    </Carousel.Item>
                                    
                                </Carousel>
                                </div>
                                </>
                            ) : (false)
                        }
                        {
                            data.id15 ? (
                                <>
                                <div className="d-flex justify-content-center">
                                <Carousel className="carousel-container">
                                    
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image1}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image2}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image3}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image4}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image5}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image6}/>
                                    </Carousel.Item>
                                    
                                </Carousel>
                                </div>
                                </>
                            ) : (false)
                        }
                        {
                            data.id16 ? (
                                <>
                                <div className="d-flex justify-content-center">
                                <Carousel className="carousel-container">
                                    
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image1}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image2}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image3}/>
                                    </Carousel.Item>
                                    
                                </Carousel>
                                </div>
                                </>
                            ) : (false)
                        }
                        {
                            data.id17 ? (
                                <>
                                <div className="d-flex justify-content-center">
                                <Carousel className="carousel-container">
                                    
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image1}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image2}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image3}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image4}/>
                                    </Carousel.Item>
                                    
                                </Carousel>
                                </div>
                                </>
                            ) : (false)
                        }
                        {
                            data.id18 ? (
                                <>
                                <div className="d-flex justify-content-center">
                                <Carousel className="carousel-container">
                                    
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image1}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image2}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image3}/>
                                    </Carousel.Item>
                                    
                                </Carousel>
                                </div>
                                </>
                            ) : (false)
                        }
                        {
                            data.id19 ? (
                                <>
                                <div className="d-flex justify-content-center">
                                <Carousel className="carousel-container">
                                    
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image1}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image2}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image3}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image4}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image5}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image6}/>
                                    </Carousel.Item>
                                    
                                </Carousel>
                                </div>
                                </>
                            ) : (false)
                        } 
                        {
                            data.id20 ? (
                                <>
                                <div className="d-flex justify-content-center">
                                <Carousel className="carousel-container" controls={data.image2 ? true : false} indicators={data.image2 ? true : false}>
                                    
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image1}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image2}/>
                                    </Carousel.Item>

                                </Carousel>
                                </div>
                                </>
                            ) : (false)
                        }
                        {
                            data.id21 ? (
                                <>
                                <div className="d-flex justify-content-center">
                                <Carousel className="carousel-container" controls={data.image2 ? true : false} indicators={data.image2 ? true : false}>
                                    
                                     <Carousel.Item interval={1000}>
                                       <img className="d-block w-100" src={data.image1}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                       <img className="d-block w-100" src={data.image2}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                       <img className="d-block w-100" src={data.image3}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                       <img className="d-block w-100" src={data.image4}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                       <img className="d-block w-100" src={data.image5}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                       <img className="d-block w-100" src={data.image6}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                       <img className="d-block w-100" src={data.image7}/>
                                    </Carousel.Item>

                                </Carousel>
                                </div>
                                </>
                            ) : (false)
                        }
                        {
                            data.id22 ? (
                                <>
                                <div className="d-flex justify-content-center">
                                <Carousel className="carousel-container" controls={data.image2 ? true : false} indicators={data.image2 ? true : false}>
                                    
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image1}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image2}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image3}/>
                                    </Carousel.Item>
                                    
                                </Carousel>
                                </div>
                                </>
                            ) : (false)
                        }
                        {
                            data.id23 ? (
                                <>
                                <div className="d-flex justify-content-center">
                                <Carousel className="carousel-container" controls={data.image2 ? true : false} indicators={data.image2 ? true : false}>
                                    
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image1}/>
                                    </Carousel.Item>
                                    
                                </Carousel>
                                </div>
                                </>
                            ) : (false)
                        }
                        {
                            //PAKALZ
                            data.id24 ? (
                                <>
                                <div className="d-flex justify-content-center">
                                <Carousel className="carousel-container" controls={data.image2 ? true : false} indicators={data.image2 ? true : false}>
                                    
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image1}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image2}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image3}/>
                                    </Carousel.Item>
                                    
                                </Carousel>
                                </div>
                                </>
                            ) : (false)
                        }

                        {
                            //PAYTACA
                            data.id25 ? (
                                <>
                                <div className="d-flex justify-content-center">
                                <Carousel className="carousel-container">
                                    
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image1}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image2}/>
                                    </Carousel.Item>
                                    
                                </Carousel>
                                </div>
                                </>
                            ) : (false)
                        }
                        {
                            //Steve
                            data.id26 ? (
                                <>
                                <div className="d-flex justify-content-center">
                                <Carousel className="carousel-container">
                                    
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image1}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image2}/>
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image3}/>
                                    </Carousel.Item>
                                </Carousel>
                                </div>
                                </>
                            ) : (false)
                        }
                        {
                            //Tzo
                            /*data.id27 ? (
                                <>
                                <div className="d-flex justify-content-center">
                                <Carousel className="carousel-container" controls={data.image2 ? true : false} indicators={data.image2 ? true : false}>
                                    
                                    <Carousel.Item interval={100}>
                                        <img className="d-block w-100" src={data.image}/>
                                    </Carousel.Item>
                                    
                                </Carousel>
                                </div>
                                </>
                            ) : (false)*/
                        }
                        {
                            //Virtual
                            data.id28 ? (
                                <>
                                <div className="d-flex justify-content-center">
                                <Carousel className="carousel-container" controls={data.image2 ? true : false} indicators={data.image2 ? true : false} >
                                    
                                    <Carousel.Item interval={1000}>
                                        <img className="d-block w-100" src={data.image1}/>
                                    </Carousel.Item>
                                    
                                </Carousel>
                                </div>
                                </>
                            ) : (false)
                        }
                        {/* {
                            data.image1 ? (
                                <>
                                <div className='seven-img'>
                                    <img src={data.image1}></img>
                                </div>
                                </>
                            ) : (<p></p>)
                        }
                        {
                            data.image2 ? (
                                <>
                                <div className='seven-img'>
                                    <img src={data.image2}></img>
                                </div>
                                </>
                            ) : (<p></p>)
                        }
                        {
                            data.image2 ? (
                                <>
                                <div className='seven-img'>
                                    <img src={data.image2}></img>
                                </div>
                                </>
                            ) : (<p></p>)
                        }
                        {
                            data.image3 ? (
                                <>
                                <div className='seven-img'>
                                    <img src={data.image3}></img>
                                </div>
                                </>
                            ) : (<p></p>)
                        }
                        {
                            data.image4 ? (
                                <>
                                <div className='seven-img'>
                                    <img src={data.image4}></img>
                                </div>
                                </>
                            ) : (<p></p>)
                        }
                        {
                            data.image5 ? (
                                <>
                                <div className='seven-img'>
                                    <img src={data.image5}></img>
                                </div>
                                </>
                            ) : (<p></p>)
                        } 
                        
                       {
                            data.image6 ? (
                                <>
                                <div className='seven-img'>
                                    <img src={data.image6}></img>
                                </div>
                                </>
                            ) : (<p></p>)
                        }
                        {
                            data.image7 ? (
                                <>
                                <div className='seven-img'>
                                    <img src={data.image7}></img>
                                </div>
                                </>
                            ) : (<p></p>)
                        }
                        {
                            data.image8 ? (
                                <>
                                <div className='seven-img'>
                                    <img src={data.image8}></img>
                                </div>
                                </>
                            ) : (<p></p>)
                        } 
                        {
                            data.image9 ? (
                                <>
                                <div className='seven-img'>
                                    <img src={data.image9}></img>
                                </div>
                                </>
                            ) : (<p></p>)
                        }
                        {
                            data.image10 ? (
                                <>
                                <div className='seven-img'>
                                    <img src={data.image10}></img>
                                </div>
                                </>
                            ) : (<p></p>)
                        } 
                        {
                            data.image11 ? (
                                <>
                                <div className='seven-img'>
                                    <img src={data.image11}></img>
                                </div>
                                </>
                            ) : (<p></p>)
                        }
                        {
                            data.image12 ? (
                                <>
                                <div className='seven-img'>
                                    <img src={data.image12}></img>
                                </div>
                                </>
                            ) : (<p></p>)
                        } 
                        {
                            data.image13 ? (
                                <>
                                <div className='seven-img'>
                                    <img src={data.image13}></img>
                                </div>
                                </>
                            ) : (<p></p>)
                        } 
                        {
                            data.image14 ? (
                                <>
                                <div className='seven-img'>
                                    <img src={data.image14}></img>
                                </div>
                                </>
                            ) : (<p></p>)
                        }*/}
                        {/* <div className='seven-img'>
                            <img src={data.image1}></img>
                        </div> 
                        <div className='seven-img'>
                            <img src={data.image2}></img>
                        </div>
                        <div className='seven-img'>
                            <img src={data.image3}></img>
                        </div>
                        <div className='seven-img'>
                            <img src={data.image4}></img>
                        <div className='seven-img'>
                            <img src={data.image6}></img>
                        </div>
                        </div> 
                        <div className='seven-img'>
                            <img src={data.image7}></img>
                        </div>
                        <div className='seven-img'>
                            <img src={data.image8}></img>
                        </div>
                        <div className='seven-img'>
                            <img src={data.image9}></img>
                        </div>
                        <div className='seven-img'>
                            <img src={data.image10}></img>
                        </div>
                        <div className='seven-img'>
                            <img src={data.image11}></img>
                        </div>
                        <div className='seven-img'>
                            <img src={data.image12}></img>
                        </div>
                        <div className='seven-img'>
                            <img src={data.image13}></img>
                        </div>
                        <div className='seven-img'>
                            <img src={data.image14}></img>
                        </div>  */}
                </Row>
                <Footer/>
                <ScrollToTop/>
            </div>
        </div>
        );
    }

export default Store;

